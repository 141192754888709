import React from "react";

const VideoPlayer = (props) => {
  const { setPlayerDOMRef, videoLink } = props
  const videoURL = videoLink

  return (
    <div
      className={`plyr__video-embed plyr-video umer-plyr`}
      ref={ref => setPlayerDOMRef && setPlayerDOMRef(ref)}
      data-plyr-embed-id={videoURL}
      data-plyr-provider="youtube"
    ></div>
  )
}

export default VideoPlayer;