import React, { useEffect, useState, useMemo, createRef, useRef } from 'react';
import Typography from '../../../../components/atoms/Typography';
import Picture from '../../../../components/atoms/Picture';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import aspectRatios from '../../../../utils/AspectRatios';
import SlideInner from '../SlideInner';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import Tab from '@material-ui/core/Tab';
import Button from '../../../../components/atoms/Button';
import TabPanel from '@material-ui/lab/TabPanel';
import clsx from 'clsx';
import { canUseDOM } from '../../../../utils/canUseDOM';
import { get, isEmpty } from 'lodash';
import {
  pushAnaylyticsData,
  createDynamicLabels,
} from '../../../../../utils/analytics';
import { gsap } from 'gsap';
import Slider from 'react-slick';
import { settings, settingsInner } from '../config';
import { Box } from '@material-ui/core';
import { NextArrow, PrevArrow } from '../../../../components/molecules/CustomArrows';
import DisplayImageOrVideo from '../../../../components/organism/DisplayImageOrVideo';
import Overlay from '../../../../components/atoms/Overlay';
import Slide from '../../../../components/organism/HeroCarousel/Slide';
import PDFImagePlaceholder from '../../../../../assets/images/PDFImagePlaceholder.jpg';
import { plyrVideo } from '../../../../utils/video';
import VideoPlayer from '../video-plyr';

// const ConditionalWrapper = ({ condition, wrapper, children }) =>
//   condition ? wrapper(children) : children;

const PanelsWrap = React.memo((props) => {
  const [tabsValue, setTabsValue] = useState(0);
  const [expanded, setExpanded] = useState('panel0');
  const { slidesData, isRTL, classes, isMobile, isEventsHeroSlider } = props;

  // --------------- isEventsHeroSlider ---------------
  const [handleOpenState, setOpenState] = useState(false);
  const [isOverlayVisible, setVisibleState] = useState(false);
  const [handleBtnClickHandler, setHandleBtnClickHandler] = useState(false);
  const PlayButtonVideolink = get(slidesData[props.initialSlideState], 'fields.playButtonVideolink.value', false);
  const youkuVideo = get(slidesData[props.initialSlideState], 'fields.youkuVideo.value', false);
  // --------------- isEventsHeroSlider ---------------

  const accordionRefs = useMemo(
    () => slidesData.map(() => createRef()),
    [slidesData.join(',')]
  )

  const slideInnerRefs = useMemo(
    () => slidesData.map(() => createRef()),
    [slidesData.join(',')]
  );

  const swipeInnerSlideAnalyticsInfo = (data) => {
    const obj = {
      ...props.analyticsData.pageBodyInteraction.dualHeroSwipeInnerSlideClick,
      ...{
        label: createDynamicLabels([
          props.pageName,
          props.componentName,
          get(slidesData[props.initialSlideState], 'fields.title.value', ''),
          get(data, 'fields.title.value', ''),
        ]),
      },
    };
    pushAnaylyticsData(obj);
  };

  const addAnalyticsInfo = (i) => {
    const seoText = get(slidesData[i], 'fields.seoEnglishCTA.value', '');
    const obj = {
      ...props.analyticsData.pageBodyInteraction.arrowClick,
      ...{
        label: seoText,
      },
    };
    pushAnaylyticsData(obj);
  };

  const slideInnerSliderRefs = useMemo(
    () => slidesData.map(() => createRef()),
    [slidesData.join(',')]
  );

  useEffect(() => {
    if (isRTL && isEventsHeroSlider) {
      setTimeout(() => {
        slideInnerSliderRefs[0].current.slickGoTo(slideInnerSliderRefs[0].current.props.children.length)
      }, 1000)
      setTimeout(() => {
        if (canUseDOM) {
          const elements = document.getElementsByClassName('slick-slider');
          for (let i = 0; i < elements.length; i++) {
            elements[i].style.opacity = 1; // Set opacity to 1
          }
        }
      }, 1500)
    }
  }, [])

  const tabsLinks = useMemo(
    () => slidesData.map(() => createRef()),
    [slidesData.join(',')]
  )

  const createSVG = (type = '', fill = '', customClass = '') => {
    switch (type) {
      case 'play':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M8 5.77797V18.222C7.99996 18.3604 8.03474 18.4963 8.10074 18.6157C8.16675 18.7351 8.26158 18.8336 8.37547 18.9011C8.48936 18.9686 8.61818 19.0027 8.74863 18.9998C8.87908 18.9969 9.00644 18.9572 9.11757 18.8847L18.6506 12.6626C18.7574 12.5931 18.8456 12.4956 18.9067 12.3796C18.9679 12.2636 19 12.1329 19 12C19 11.8671 18.9679 11.7364 18.9067 11.6204C18.8456 11.5044 18.7574 11.4069 18.6506 11.3374L9.11757 5.11533C9.00644 5.04281 8.87908 5.00306 8.74863 5.00017C8.61818 4.99728 8.48936 5.03136 8.37547 5.09889C8.26158 5.16641 8.16675 5.26495 8.10074 5.38432C8.03474 5.5037 7.99996 5.63959 8 5.77797Z" fill="white" />
          </svg>
        )
      default:
        return <></>
    }
  }

  const handleAccordionChange =
    (panel, id) => (_event, isExpanded) => {
      updateInnerSlider(id);
      if (!isExpanded) { return false; }
      let nextAccordion = accordionRefs[id].current;
      const oldAccordionId = expanded.replace(/^\D+/g, '');
      let currentSelectedAccordion = accordionRefs[oldAccordionId].current;
      gsap.timeline()
        .fromTo(currentSelectedAccordion.querySelectorAll('.accordionDetails .detailsTitle, .accordionDetails a > *'), {
          opacity: 1,
        }, {
          opacity: 0,
          duration: 0.25,
        }).fromTo(nextAccordion.querySelector('.accordionDetails .detailsTitle'), {
          opacity: 0,
        }, {
          opacity: 1,
          duration: 0.4,
        }, '-=0.1').fromTo(nextAccordion.querySelector('.accordionDetails a > *'), {
          opacity: 0,
          y: 10,
        }, {
          opacity: 1,
          y: 0,
          duration: 0.35,
        }, '-=0.15');
      setExpanded(isExpanded ? panel : false);
    };

  const handleTabChange = (event, id) => {
    if (id == tabsValue) { return false; }
    updateInnerSlider(id, false);
    setTabsValue(id);
  };

  const sliderInOut = (currentElement, direction = 'in', animateMe = true) => {
    const firstSlide = currentElement.querySelector('.slick-slide.slick-active') || null;
    const secondSlide = firstSlide && firstSlide.nextElementSibling || null;
    const thirdSlide = secondSlide && secondSlide.nextElementSibling || null;
    const animateGap = 23;
    const animateGapTotal = 28;
    const onCompleteFun = () => {
      gsap.set(firstSlide, { x: 0 })
      gsap.set(secondSlide, { x: 0 })
      gsap.set(thirdSlide, { x: 0 })
    };
    let thisTL;
    if (direction === 'out') {
      thisTL = gsap.timeline({
        paused: true,
        ease: 'power2.out',
      });
      firstSlide && thisTL.fromTo(firstSlide, {
        x: 0,
      }, {
        x: isRTL ? animateGap : -animateGap,
        duration: 0.4,
      });
      secondSlide && thisTL.fromTo(secondSlide, {
        x: 0,
      }, {
        x: isRTL ? animateGap * 0.75 : -(animateGap * 0.75),
        duration: 0.3,
      }, '-=0.3')
      thirdSlide && thisTL.fromTo(thirdSlide, {
        x: 0,
      }, {
        x: isRTL ? animateGap * 0.5 : -(animateGap * 0.5),
        duration: 0.2,
      }, '-=0.2')
      currentElement && thisTL.fromTo(currentElement, {
        x: 0,
        opacity: 1,
      }, {
        x: isRTL ? (animateGapTotal - animateGap) : -(animateGapTotal - animateGap),
        opacity: 0,
        duration: 0.3,
      }, '-=0.3');
    } else {
      firstSlide && gsap.set(firstSlide, {
        x: 0,
      });
      thisTL = gsap.timeline({
        ease: 'power1.out',
        paused: true,
        onComplete: onCompleteFun,
      }).fromTo(currentElement, {
        x: isRTL ? -(animateGapTotal - animateGap) : animateGapTotal - animateGap,
        opacity: 0,
      }, {
        x: 0,
        opacity: 1,
        duration: 0.4,
      });
      firstSlide && thisTL.fromTo(firstSlide, {
        x: isRTL ? -animateGap : animateGap,
      }, {
        x: 0,
        duration: 0.4,
      }, '-=0.4');
      secondSlide && thisTL.fromTo(secondSlide, {
        x: isRTL ? -(animateGap * 1.5) : animateGap * 1.5,
      }, {
        x: 0,
        duration: 0.4,
      }, '-=0.4');
      thirdSlide && thisTL.fromTo(thirdSlide, {
        x: isRTL ? -(animateGap * 2) : animateGap * 2,
      }, {
        x: 0,
        duration: 0.4,
      }, '-=0.4');
    }
    if (animateMe) {
      thisTL.play();
    } else {
      thisTL.seek('-=0', false);
    }
  }

  const updateInnerSlider = (id, animateMe = true, firstExecute = false) => {
    slideInnerRefs[id].current.classList.add('show-me');
    if (!firstExecute) {
      if (!slideInnerRefs[id].current.classList.contains('has-no-data')) {
        setTimeout(() => {
          sliderInOut(slideInnerRefs[id].current, 'in', animateMe);
        }, animateMe ? 350 : 0);
      }
    }
    if (!firstExecute) {
      slideInnerRefs.forEach((ref, index) => {
        if (ref.current.classList.contains('show-me') && index !== id && !ref.current.classList.contains('has-no-data')) {
          sliderInOut(ref.current, 'out', animateMe);
        }
        if (index !== id) {
          ref.current.classList.remove('show-me');
        }
      });
      props.matchActiveTabWithSlider(id, false);
    }
  }


  const showInnerSliderArrows = () => {
    const rightPanel = props.dualHeroSliderRef.current.querySelector('.panelsWrap .rightPanel');
    rightPanel.classList.add('show-arrows');
  }

  const dynamicSettings = (carouselItems) => {
    const lastIdx = carouselItems ? carouselItems.length - 1 : 0;
    if (isEventsHeroSlider) {
      return {
        dots: true,
        arrows: true,
        infinite: false,
        slidesToShow: 1,
        rtl: false,
        nextArrow: <NextArrow colorVariant={'whiteVariant'} />,
        prevArrow: <PrevArrow colorVariant={'whiteVariant'} />,
      };
    } else {
      return settingsInner
    }
  }


  // --------------- isEventsHeroSlider ---------------
  const item = {};

  // Video data for <DisplayImageOrVideo> (Just the button that initiates the video <Overlay>)
  const data = {
    "video": {
      "value": {
        "href": PlayButtonVideolink,
        "url": "https://www.youtube.com/embed/t2eDdWc9zvw",
        "linktype": "external"
      }
    },
    "youkuVideo": {
      "value": ""
    },
    "image": {
      "value": item?.thumbnailImage || PDFImagePlaceholder
    },
    "thumbnailImage": {
      "value": item?.thumbnailImage || PDFImagePlaceholder
    },
    "mobileImage": {
      "value": item?.thumbnailImage?.src || PDFImagePlaceholder
    }
  }

  // Actual video data that is being used for <Overlay>
  const vid = {
    "fields": {
      "video": {
        "value": {
          "href": PlayButtonVideolink || '', // The actual link for the video
          // "href": "https://www.youtube.com/embed/t2eDdWc9zvw" || '',
          "url": '',
          "linktype": "external"
        }
      },
      "youkuVideo": {
        "value": youkuVideo
      },
      "image": {
        "value": item?.thumbnailImage || PDFImagePlaceholder
      },
      "thumbnailImage": {
        "value": item?.thumbnailImage || PDFImagePlaceholder
      },
      "mobileImage": item?.thumbnailImage?.src || PDFImagePlaceholder
    }
  }

  const poster = [
    "/-/media/project/vad/homepage/new-rebrand-images/hero-carousel/nov-first/slide-1/rtelal---chinese-couple---3-1-nov.jpg"
  ]

  const [isIos, setIsIos] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const setIsVisible = isVisible => {
    setVisibleState(isVisible);
    setHandleBtnClickHandler(!isVisible);
  };

  let playerDOMRef = '';
  const setPlayerDOMRef = ref => {
    playerDOMRef = ref;
  };

  useEffect(() => {
    // Detect if the device is iOS
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setIsIos(true);
    }

    setIsMounted(true); // Indicate that the component has mounted

    if (!item) return
    if (playerDOMRef) {
      plyrVideo({
        playerDOMRef: playerDOMRef,
        shouldShowPoster: true,
        handleOpenState,
        posterImage: '',
        fullScreenControl: true
      });
    }
  }, [item])

  const playVideo = video => {
    setVisibleState(true)
    const videoHref = get(video, 'href', '') || '';
    const hasVideo = !isEmpty(videoHref);
    setOpenState(hasVideo);
    if (hasVideo && canUseDOM) {
      setHandleBtnClickHandler(true);
      let playBtn = document.querySelector(
        '.plyr__control--overlaid'
      );
      let playBtnYouku = document.querySelector('.slick-active .youkuPlayIcon');
      const playBtnInterval = setInterval(() => {
        playBtn = document.querySelector(
          '.plyr__control--overlaid'
        );
        playBtnYouku = document.querySelector('.youkuPlayIcon');
        if (playBtn || playBtnYouku) {
          clearInterval(playBtnInterval);
          setTimeout(() => {
            // setOpenState(false);
            playBtn && playBtn.click();
            playBtnYouku && playBtnYouku.click();
          }, 100);
        }
      }, 100);
    }
  };

  // // --------------- isEventsHeroSlider ---------------
  return (
    <>
      <div className={'panelsWrap'}>
        <div className={'leftPanel'}>
          {isMobile === '' || isMobile === 'mobile' && (
            <div className={'is-mobile'}>
              <TabContext value={tabsValue}>
                <TabList
                  className={'tabLink'}
                  value={tabsValue}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                >
                  {slidesData.map((item, i) => (
                    <Tab key={i}
                      label={item && item.fields && item.fields.title && item.fields.title.value}
                      id={`tab-id-${i}`}
                      classes={{ textColorInherit: classes.mobileTab }}
                      ariaControls={`tabpanel-id${i}`}
                      ref={tabsLinks[i]}
                    />
                  ))}
                </TabList>
                {slidesData.map((item, i) => {
                  const fields = item?.fields;
                  const title = fields?.innerTitle?.value;
                  const cta = fields?.cta;
                  const PlayButtonText = get(item, 'fields.playButtonText.value', 'Play');
                  return (
                    <div key={i}>
                      <TabPanel value={i} index={i}>
                        <div className={'titleWrapper'}>
                          <Typography variant="h2" component="h3"
                            className={'detailsTitle'}
                            title={title}
                            animateSettings={{
                              willAnimate: false,
                            }}>
                            {title}
                          </Typography>
                          {cta && cta.value.href && cta.value.text && (
                            isEventsHeroSlider
                              ? (<Box className={'detailsCTABox'}>
                                <Typography variant="body2" component="p"
                                  animateSettings={{
                                    willAnimate: false,
                                  }}>
                                  <Button
                                    className={'detailsCTA'}
                                    component="span"
                                    buttonType="primary"
                                    field={cta}
                                    hasBorder={false}
                                    animateSettings={{
                                      willAnimate: false,
                                    }}
                                    onClick={() => addAnalyticsInfo(i)}
                                  />
                                </Typography>
                                {PlayButtonVideolink &&
                                  <>
                                    {isMounted && isIos && (
                                      <Box className={`iphoneWrapper`}>
                                        <VideoPlayer videoLink={PlayButtonVideolink} setPlayerDOMRef={setPlayerDOMRef} />
                                      </Box>
                                    )}
                                    <button
                                      className={`videoBtn ${isIos ? 'iosDevice' : ''}`}
                                      component="span"
                                    >
                                      <Box className={'iconWrapper'}>{createSVG('play')}</Box>
                                      <DisplayImageOrVideo isEventsHeroSlider={isEventsHeroSlider} mediaField={data} isParalax={false} from='videoHeight' playVideo={playVideo} playOnBoth={true} />
                                      <Typography className={'videoBtnText'} variant="body2" component="p">{PlayButtonText}</Typography>
                                    </button>
                                  </>
                                }
                                <Overlay
                                  isFlex={true}
                                  title={''}
                                  withButton={false}
                                  className={clsx(
                                    'eventsHeroSliderVideo',
                                    classes.overlayWrapper,
                                    'overlay-wrapper',
                                    { hidden: handleOpenState },
                                    { 'video-playing': handleOpenState }
                                  )}
                                  isVisible={isOverlayVisible}
                                  setIsVisible={isVisible => setIsVisible(isVisible)}
                                >
                                  {
                                    handleOpenState && (
                                      <>
                                        {/* Create Slide */}
                                        <Slide
                                          titleText={''}
                                          slide={vid}
                                          posterImages={poster}
                                          handleOpenState={handleOpenState}
                                        />
                                      </>
                                    )
                                  }
                                </Overlay>
                              </Box>)
                              : (
                                <Typography variant="h3" component="h3"
                                  animateSettings={{
                                    willAnimate: false,
                                  }}>
                                  <Button
                                    className={'detailsCTA'}
                                    component="span"
                                    buttonType="primary"
                                    field={cta}
                                    hasBorder={false}
                                    animateSettings={{
                                      willAnimate: false,
                                    }}
                                  />
                                </Typography>
                              )
                          )}
                        </div>
                      </TabPanel>
                    </div>
                  )
                }
                )}
              </TabContext>
            </div>
          )}
          {isMobile === '' || isMobile === 'desktop' && (
            <div className={'is-desktop'}>
              {slidesData.map((item, i) => {
                const fields = item?.fields;
                const innerTitle = fields?.innerTitle?.value;
                const cta = fields?.cta;
                const PlayButtonText = get(item, 'fields.playButtonText.value', 'Play');

                return (
                  <div key={i}>
                    <Accordion key={i} ref={accordionRefs[i]} className={clsx('accordion', ((slidesData.length - 1 === i) || isEventsHeroSlider) ? 'last-child' : '', slidesData.length <= 1 ? 'only-child' : '')}
                      expanded={expanded === `panel${i}`} onChange={handleAccordionChange(`panel${i}`, i)}
                      TransitionProps={{
                        timeout: 350,
                      }}>
                      <AccordionSummary className={clsx('accordionSummary', ((slidesData.length - 1 === i) || isEventsHeroSlider) ? 'last-child' : '')} aria-controls={`panel${i}a-content`} id={`panel-${i}`}>
                        <Typography variant="body2Bold" component="h2"
                          className={'title'}
                          animateSettings={{
                            willAnimate: false,
                          }}>
                          {fields && fields.title.value}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className={clsx('accordionDetails', ((slidesData.length - 1 === i) || isEventsHeroSlider) ? 'last-child' : '')}>
                        <Typography variant="h2" component="h3"
                          className={'detailsTitle'}
                          title={innerTitle}
                          animateSettings={{
                            willAnimate: false,
                          }}>
                          {innerTitle}
                        </Typography>
                        {fields && cta && cta.value.href && cta.value.text && (
                          isEventsHeroSlider
                            ? (<Box className={'detailsCTABox'}>
                              <Typography variant="body2" component="p"
                                animateSettings={{
                                  willAnimate: false,
                                }}>
                                <Button
                                  className={'detailsCTA'}
                                  component="span"
                                  buttonType="primary"
                                  field={cta}
                                  hasBorder={false}
                                  animateSettings={{
                                    willAnimate: false,
                                  }}
                                  onClick={() => addAnalyticsInfo(i)}
                                />
                              </Typography>
                              {PlayButtonVideolink &&
                                <button
                                  className={'videoBtn'}
                                  component="span"
                                >
                                  <Box className={'iconWrapper'}>{createSVG('play')}</Box>
                                  <DisplayImageOrVideo isEventsHeroSlider={isEventsHeroSlider} mediaField={data} isParalax={false} from='videoHeight' playVideo={playVideo} playOnBoth={true} />
                                  <Typography className={'videoBtnText'} variant="body2" component="p">{PlayButtonText}</Typography>
                                </button>
                              }

                              <Overlay
                                isFlex={true}
                                title={''}
                                withButton={false}
                                className={clsx(
                                  'eventsHeroSliderVideo',
                                  classes.overlayWrapper,
                                  'overlay-wrapper',
                                  { hidden: handleOpenState },
                                  { 'video-playing': handleOpenState }
                                )}
                                isVisible={isOverlayVisible}
                                setIsVisible={isVisible => setIsVisible(isVisible)}
                              >
                                {
                                  handleOpenState && (
                                    <>
                                      {/* Create Slide */}
                                      <Slide
                                        titleText={''}
                                        slide={vid}
                                        posterImages={poster}
                                        handleOpenState={handleOpenState}
                                      />
                                    </>
                                  )
                                }
                              </Overlay>
                            </Box>)
                            : (
                              <Typography variant="h3" component="h3"
                                animateSettings={{
                                  willAnimate: false,
                                }}>
                                <Button
                                  className={'detailsCTA'}
                                  component="span"
                                  buttonType="primary"
                                  field={cta}
                                  hasBorder={false}
                                  animateSettings={{
                                    willAnimate: false,
                                  }}
                                  onClick={() => addAnalyticsInfo(i)}
                                />
                              </Typography>
                            )
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                )
              })}
            </div>
          )}
        </div>
        <div className={'rightPanel'}>
          {slidesData.map((item, i) => {

            let hasData = true;
            if (typeof item.fields.carouselItems === 'undefined' || item.fields.carouselItems.length === 0) {
              hasData = false;
            }
            const carouselItems = item?.fields?.carouselItems;
            return (
              <div
                key={i}
                ref={slideInnerRefs[i]}
                className={
                  clsx(`slider-inner slider-inner-${i}`, i === 0 ? 'show-me' : 0, !hasData && 'has-no-data')}
                style={{
                  opacity: i === 0 ? 1 : 0,
                }}
              >
                {isMobile === '' || isMobile === 'mobile' && (
                  <div className={'is-mobile'}>
                    {
                      carouselItems && carouselItems.map((itemInner, y) => (
                        <React.Fragment key={y}>
                          <SlideInner
                            key={y}
                            index={y}
                            initInnerSlides={props.initInnerSlides}
                            slide={itemInner}
                            componentName={`${props.componentName}-${carouselItems.id}`}
                            pageName={props.pageName}
                            isParalax={false}
                            className={'slide-inner-slide'}
                            isEventsHeroSlider={isEventsHeroSlider}
                          />
                        </React.Fragment>
                      ))
                    }
                    {props.updateInitialSlideState(true)}
                  </div>
                )}
                {isMobile === 'desktop' && (
                  <div className={'is-desktop'}>
                    {/* Note: showing this slider in mobile will break UI class build on server for some unkown reason. */}
                    <Slider
                      afterChange={i => {
                        swipeInnerSlideAnalyticsInfo(carouselItems[i]);
                      }}
                      onInit={() => {
                        if (carouselItems.length > 1) {
                          setTimeout(() => {
                            showInnerSliderArrows()
                          }, 10);
                          props.updateInitialSlideState(true)
                        }
                      }}
                      ref={slideInnerSliderRefs[i]}
                      {...dynamicSettings(carouselItems)}
                    >
                      {carouselItems && carouselItems.map((itemInner, y) => (
                        <React.Fragment key={y}>
                          <SlideInner
                            key={y}
                            index={y}
                            initInnerSlides={props.initInnerSlides}
                            slide={itemInner}
                            componentName={`${props.componentName}-${item.fields.carouselItems.id}`}
                            pageName={props.pageName}
                            isParalax={false}
                            className={'slide-inner-slide'}
                            isEventsHeroSlider={isEventsHeroSlider}
                          />
                        </React.Fragment>
                      ))}
                    </Slider>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
});

export default withSitecoreContext()(withNamespaces()(PanelsWrap));