import React, { useEffect, useState, useRef, useMemo, createRef, lazy, Suspense, useCallback } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { useTheme } from '@material-ui/core/styles';
import '../../../../styles/themes/slick-themes.css';
import { settings, settingsInner } from './config';
import Slide from './Slide';
import { get } from 'lodash';
import useStyles from './style';
import { COMPONENT_NAME } from './locators';
import { canUseDOM } from '../../../utils/canUseDOM';
import {
  getComponentName,
  getPageName,
} from '../../../utils/getPageComponentInfo';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import {
  pushAnaylyticsData,
  createDynamicLabels,
} from '../../../../utils/analytics';
import { analyticsData } from '../../../../constants/Analytics/analytics-data';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { getUId } from '../../../../utils/utility';
import DctSvgIcon from '../../../components/atoms/Svg';
import Button from '../../../components/atoms/Button';
import { Helmet } from "react-helmet";
import { parseToLDJson } from "./parseToLDJson";
import '../../../utils/styles/utils.css';
import { initializeFirstVideo, setSeekToZeroOfSlide, initializeFirstYoukuVideo } from './redux/actions';
import { connect } from 'react-redux';
import PanelsWrap from './PanelsWrap';
import ReactPlayer from 'react-player';
import { Box } from '@material-ui/core';
import Picture from '../../../components/atoms/Picture';

let oldIndex = null;
let newIndex = null;
let matchActiveTabWithSliderInterval = null;

const DualHeroSlider = props => {
  const { initializeFirstVideo, initializeFirstYoukuVideo, setSeekToZeroOfSlide } = props;
  const pageName = getPageName(props);
  const componentName = getComponentName(props);
  const language = get(props, 'sitecoreContext.language', '');
  const slidesData = get(props, 'fields.slides');
  const uid = getUId(props)
  const [initialSlideState, setInitialSlideState] = useState(0);
  const [componentIndex, setComponentIndex] = useState(Math.random() * 10);
  const [isMobile, setIsMobile] = useState('');
  const [initInnerSlides, setInitInnerSlides] = useState(false);
  const theme = useTheme();
  const isRTL = theme && theme.direction === 'rtl';
  const sliderRef = useRef(null);
  const dualHeroSliderRef = useRef(null);
  const scrollDownRef = useRef(null);
  const schemaLDJson = parseToLDJson(slidesData, language);

  const isEventsHeroSlider = 'Event' === get(props, 'params.Style', false);
  const supergraphicOverlay = get(props, 'fields.slides[0].fields.superGraphicImage', '');

  const updateInitialSlideState = (updatedValue) => {
    setInitInnerSlides(updatedValue)
  };

  useEffect(() => {
    if (canUseDOM) {
      const setIsMobileFunc = () => {
        setIsMobile(window.innerWidth < 768 ? 'mobile' : 'desktop');
      }

      setIsMobileFunc();

      gsap.registerPlugin(ScrollToPlugin);
      if (scrollDownRef && scrollDownRef.current) {
        const scrollDownSvg = scrollDownRef && scrollDownRef.current.querySelector('svg');
        gsap.timeline({
          repeat: -1,
          ease: 'Power1.easeInOut',
        }).fromTo(scrollDownSvg, {
          y: 30,
          opacity: 0,
        }, {
          y: 0,
          opacity: 0,
          ease: 'none',
          duration: 0.3,
        }).fromTo(scrollDownSvg, {
          y: 0,
        }, {
          y: 30,
          ease: 'none',
          duration: 2,
        }).fromTo(scrollDownSvg, {
          opacity: 0,
        }, {
          opacity: 1,
          ease: 'none',
          duration: 1,
        }, '-=2').fromTo(scrollDownSvg, {
          opacity: 1,
        }, {
          opacity: 0,
          ease: 'none',
          duration: 1,
        }, '-=1');
      }

      if (language === 'zh') {
        window.addEventListener('click', playYoukuVideoOnFirstInteraction);
        window.addEventListener('scroll', playYoukuVideoOnFirstInteraction);
      }

      window.addEventListener('resize', () => {
        setIsMobileFunc()
      });
    }
  }, []);

  function playYoukuVideoOnFirstInteraction() {
    if (canUseDOM && language === 'zh') {
      setTimeout(() => {
        initializeFirstYoukuVideo(true)
        window.removeEventListener('click', playYoukuVideoOnFirstInteraction)
        window.removeEventListener('scroll', playYoukuVideoOnFirstInteraction)
      }, 2500);
    }
  }

  const switchSlideAnalyticsInfo = index => {
    const obj = {
      ...analyticsData.pageBodyInteraction.dualHeroSwitchSlideClick,
      ...{
        label: createDynamicLabels([
          pageName,
          componentName,
          get(slidesData[index], 'fields.title.value', ''),
        ]),
      },
    };
    pushAnaylyticsData(obj);
  };


  const scrollDown = () => {
    let notificationBarHeight = document.documentElement.style.getPropertyValue(
      '--notificationBar'
    );
    notificationBarHeight = parseInt(notificationBarHeight);

    let heightToScroll = (window.androidHeight || window.innerHeight);
    if (heightToScroll < 590) {
      heightToScroll = 590;
    }
    const scrollToPos = heightToScroll + notificationBarHeight;
    gsap.to(window, {
      duration: 1,
      scrollTo: scrollToPos,
      ease: 'power1.inOut',
    });
  };

  settingsInner.rtl = isRTL;
  settingsInner.initialSlide = isRTL ? 1 : 0;

  const classes = useStyles();

  const matchActiveTabWithSlider = (targetIndex, animate) => {
    clearInterval(matchActiveTabWithSliderInterval);
    matchActiveTabWithSliderInterval = setInterval(() => {
      if (targetIndex !== newIndex) {
        sliderRef.current.slickGoTo(targetIndex, animate);
      }
    }, 150);
  };

  let triedToFindVideoCount = 0;
  const initializeVideoComponents = () => {
    const mainSliderEle = sliderRef.current;
    const innerSliderList = mainSliderEle?.innerSlider?.list.children[0];
    let firstYoutubeVideoIndex = null;
    if (innerSliderList) {
      for (var i = 0; i < innerSliderList.children.length; i++) {
        var item = innerSliderList.children[i];
        if (item.querySelector('div[elementid="background-video"]')) {
          firstYoutubeVideoIndex = i
          initializeFirstVideo(firstYoutubeVideoIndex)
          return;
        }
      }
    }
    if (firstYoutubeVideoIndex === null && triedToFindVideoCount < 50) {
      setTimeout(() => {
        triedToFindVideoCount++;
        initializeVideoComponents();
      }, 10)
    }
  }

  const seekToZero = (oldSlide, newSlide) => {
    setSeekToZeroOfSlide({
      oldSlide,
      newSlide,
    })
  }

  const playActiveVideo = (index) => {
    setInitialSlideState(index)
    const mainSliderEle = sliderRef.current;
    const innerSliderList = mainSliderEle.innerSlider.list.children[0];
    const activeSlide = innerSliderList.children[index];
    const videoElement = activeSlide.querySelector('div[elementid="background-video"] video');

    if (videoElement) {
      videoElement.play();
    }
  };

  return (
    <React.Fragment>
      <div
        data-locator={COMPONENT_NAME}
        className={`hero-banner-section ${classes.root} ${componentIndex}${isEventsHeroSlider ? ' eventsHeroSlider' : ''}`}
        ref={dualHeroSliderRef}
        key={componentIndex}
        uid={uid}
      >
        <Helmet>
          <script type="application/ld+json">{`${schemaLDJson}`}</script>
        </Helmet>
        {isEventsHeroSlider ? (
          <Box className={'bg-wrapper-eventsHeroSlider'}>
            <div className={'supergraphic-overlay-bg'}>
              <Picture
                media={{
                  image: supergraphicOverlay,
                }}
              />
            </div>
            <Slider
              beforeChange={(oi, ni) => {
                oldIndex = oi;
                newIndex = ni;
                seekToZero(oi, ni);
                playActiveVideo(ni);
              }}
              afterChange={i => {
                oldIndex = i;
                newIndex = i;
                switchSlideAnalyticsInfo(i);
              }}
              onInit={() => {
                setTimeout(() => {
                  // updateInnerSlider(0, false, true);
                  initializeVideoComponents();
                }, 10);
              }}
              className={'bgSlider'}
              initialSlide={initialSlideState}
              ref={sliderRef}
              {...settings}
            >
              {slidesData &&
                slidesData.map((item, i) => (
                  <Slide
                    key={i}
                    isCurrentSlide={initialSlideState === i}
                    oldIndex={oldIndex}
                    newIndex={newIndex}
                    index={i}
                    isMobile={isMobile}
                    initInnerSlides={initInnerSlides}
                    slide={item}
                    componentName={componentName}
                    pageName={pageName}
                    isParalax={false}
                    videosettingsInner={{
                      controls: {
                        fullScreen: true,
                      },
                    }}
                  />
                )
                )}
            </Slider>
          </Box>
        ) : (
          <Slider
            beforeChange={(oi, ni) => {
              oldIndex = oi;
              newIndex = ni;
              seekToZero(oi, ni);
              playActiveVideo(ni);
            }}
            afterChange={i => {
              oldIndex = i;
              newIndex = i;
              switchSlideAnalyticsInfo(i);
            }}
            onInit={() => {
              setTimeout(() => {
                // updateInnerSlider(0, false, true);
                initializeVideoComponents();
              }, 10);
            }}
            className={'bgSlider'}
            initialSlide={initialSlideState}
            ref={sliderRef}
            {...settings}
          >
            {slidesData &&
              slidesData.map((item, i) => (
                <Slide
                  key={i}
                  isCurrentSlide={initialSlideState === i}
                  oldIndex={oldIndex}
                  newIndex={newIndex}
                  index={i}
                  isMobile={isMobile}
                  initInnerSlides={initInnerSlides}
                  slide={item}
                  componentName={componentName}
                  pageName={pageName}
                  isParalax={false}
                  videosettingsInner={{
                    controls: {
                      fullScreen: true,
                    },
                  }}
                />
              )
              )}
          </Slider>
        )}
        <PanelsWrap
          slidesData={slidesData}
          analyticsData={analyticsData}
          pageName={pageName}
          componentName={componentName}
          initialSlideState={initialSlideState}
          dualHeroSliderRef={dualHeroSliderRef}
          matchActiveTabWithSlider={matchActiveTabWithSlider}
          isRTL={isRTL}
          classes={classes}
          initInnerSlides={initInnerSlides}
          updateInitialSlideState={updateInitialSlideState}
          isMobile={isMobile}
          isEventsHeroSlider={isEventsHeroSlider}
        />
        <Button
          className={'scrollDownButton'}
          iconOnly
          onClick={scrollDown}
          animateSettings={{
            willAnimate: false,
          }}
        >
          <div ref={scrollDownRef}>
            <DctSvgIcon
              name="ArrowRoundedDown"
            />
          </div>
        </Button>
      </div>
    </React.Fragment>
  );
};

export const mapDispatchToProps = dispatch => {
  return {
    initializeFirstVideo: params => dispatch(initializeFirstVideo(params)),
    initializeFirstYoukuVideo: params => dispatch(initializeFirstYoukuVideo(params)),
    setSeekToZeroOfSlide: params => dispatch(setSeekToZeroOfSlide(params)),
  };
};

export const mapStateToProps = () => {
  return {}
}

export default withErrorBoundary(withSitecoreContext()(connect(mapStateToProps, mapDispatchToProps)(DualHeroSlider)));