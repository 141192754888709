import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../styles/themes/vad/constants';
import { fontFamilyEN as fontFamily, fontSize } from '../../../../styles/themes/vad/font';
import { translate } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    color: theme.palette.common.White,
    backgroundColor: theme.palette.common.defaultBgGrey,
    height: 'calc(var(--vh-static, 1vh) * 100) !important',
    minHeight: '500px',

    // ------------------------------- > Events Hero Slider -------------------------------

    '&.eventsHeroSlider': {
      height: 'calc(var(--vh-static, 1vh) * 100) !important',
      marginTop: `calc(0px - var(--headerHeight))`,
      '& .panelsWrap': {
        zIndex: 2,

        '& .leftPanel': {
          '& .is-mobile': {

            '& .tabLink': {
              display: 'none',
            },

            '& .titleWrapper': {
              display: 'flex',
              flexDirection: 'column',
              gap: pxToRem(24),

              '& .detailsCTABox': {
                flexDirection: 'column',
                gap: pxToRem(16),
              },
            },


            '& .detailsTitle': {
              fontSize: pxToRem(40),
              lineHeight: pxToRem(48),
            },
          },

          '& .is-desktop': {

          },

          '& .accordion': {
            margin: '0',
          },

          '& .accordionSummary': {
            display: "none",
          },

          '& .detailsCTA': {
            marginTop: 0,
          },

          '& .detailsTitle': {
            color: theme.mixins.White(),
            fontSize: pxToRem(72),
            fontStyle: 'normal',
            lineHeight: theme.isDirectionRTL ? pxToRem(110) : pxToRem(80),

            '@media (max-width: 1199px)': {
              fontSize: '3rem',
              lineHeight: '1.2em',
            },
          },

          '& .accordionDetails': {
            display: 'flex',
            flexDirection: 'column',
            gap: pxToRem(40),
            padding: 0,
          },

          '& .detailsCTABox': {
            display: 'flex',
            gap: pxToRem(32),
            position: 'relative',
            '@media (max-width: 1199px)': {
              '& .MuiTypography-body2': {
                zIndex: 1,
              },
              flexDirection: 'column',
            },

            '& .iphoneWrapper': {
              position: 'absolute',
              '& .plyr': {
                height: 'auto',
                background: 'transparent',

                '& .plyr__video-wrapper': {
                  opacity: 0,
                  pointerEvents: 'none'
                }
              },
              '& .plyr__control--overlaid--ios': {
                top: pxToRem(86.4),
                ...theme.mixins.left(22.4),
                '[dir="rtl"] &': {
                  ...theme.mixins.left(90),
                },
                '&::after': {
                  position: 'absolute',
                  ...theme.mixins.right(-90),
                  top: '12px',
                  content: "''",
                  width: pxToRem(90),
                  height: '20px',
                }
              },
            },

            '& .detailsCTA': {
              padding: pxToRem(12) + " " + pxToRem(24),
              borderRadius: pxToRem(100),
              backgroundColor: theme.mixins.SeaGreen(),

              '& .MuiButton-label': {
                gap: pxToRem(8),
              },

              '& .btn-txt': {
                fontFamily: theme.custom.fontFamily.primaryBold,
                fontSize: pxToRem(16),
                lineHeight: pxToRem(24),
                fontWeight: '400',
              },

              '& .MuiButton-endIcon': {
                // display: 'none',
                backgroundColor: 'transparent',
                padding: 0,
                margin: 0,

                '& svg': {
                  color: theme.mixins.White(),
                }
              }
            },

            '& .videoBtn': {
              position: 'relative',
              display: 'flex',
              flexDirection: theme.isDirectionRTL ? 'row-reverse' : 'row',
              justifyContent: theme.isDirectionRTL ? 'flex-end' : 'flex-start',
              alignItems: 'center',
              gap: pxToRem(10),
              padding: 0,
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              width: 'fit-content',

              '&.iosDevice': {
                pointerEvents: 'none',
                '& .iconWrapper': {
                  opacity: 0
                }
              },

              '@media (min-width: 1150px)': {
                '&:hover': {
                  '& .iconWrapper': {
                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                  },
                },
              },

              '& .iconWrapper': {
                padding: pxToRem(9),
                borderRadius: '50%',
                border: '1px solid #FFF',
                display: 'flex',
                transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              },

              '& .videoBtnText': {
                fontFamily: theme.custom.fontFamily.primaryBold,
                color: theme.mixins.White(),
                fontSize: pxToRem(16),
              },

              '& .overlayPicture': {
                display: 'none',
              },

              '& .playIcon': {
                position: 'absolute',
                top: '0',
                bottom: 'unset',
                left: '0',
                right: 'unset',
                transform: 'translate(0, 0)',
                width: '100%',
                height: '100%',
                margin: 0,
                opacity: 0,
              },
            }
          },

          '@media (max-height: 700px)': {
            marginTop: pxToRem(72),
          },
        },

        '& .rightPanel': {
          width: '51.95rem',

          '@media (max-width: 1919px)': {
            width: '31.95rem',
          },

          '@media (max-width: 1299px)': {
            transform: 'translate(0, -50%)',
          },

          '@media (max-width: 919px)': {
            width: '45%',
          },

          '@media (max-width: 767px)': {
            width: '100%',
            marginBottom: '0',
          },


          '& .slider-inner': {
            paddingLeft: theme.isDirectionRTL ? '10.65rem' : '0.55rem',
            paddingRight: theme.isDirectionRTL ? '0' : '10.65rem',

            '@media (max-width: 919px)': {
              paddingLeft: theme.isDirectionRTL ? '33.5%' : '1rem',
              paddingRight: theme.isDirectionRTL ? '0' : '33.5%',
            },

            '@media (max-width: 767px)': {
              ...theme.mixins.paddingRight('1.25rem'),
              ...theme.mixins.paddingLeft('1rem'),
              paddingBottom: pxToRem(9),
            },

            '& .is-desktop, & .is-mobile': {

              '& .slick-slider': {

              },

              '& .slide-inner-slide': {
                '& .slideItemsWrapper': {
                  position: 'relative',

                  '& .slidePictureWrapper': {
                    borderRadius: pxToRem(10),
                    maxHeight: pxToRem(408),

                    '@media (max-width: 767px)': {
                      minHeight: pxToRem(408),
                    },

                    '@media (max-height: 799px)': {
                      minHeight: pxToRem(358),
                    },

                    '@media (max-height: 738px)': {
                      minHeight: pxToRem(308),
                    },

                    '& .slideElement': {
                      transform: 'translateY(0)',

                      '&:after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                        height: '100%',
                        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)',
                        pointerEvents: 'none',
                      },
                    },
                  },

                  '& .slideInformation': {
                    position: 'absolute',
                    bottom: 0,
                    justifyContent: 'space-between',
                    height: '100%',
                    padding: pxToRem(16) + ' ' + pxToRem(16) + ' ' + pxToRem(24),

                    '& .slideInformationTop': {
                      display: 'flex',
                      flexDirection: theme.isDirectionRTL ? 'row-reverse' : 'row',

                      '@media (max-width: 767px)': {
                        flexDirection: 'row',
                      },

                      '& .categoryLabel': {
                        padding: pxToRem(4) + ' ' + pxToRem(8),
                        borderRadius: pxToRem(3),
                        backgroundColor: theme.mixins.White(),

                        '& .categoryLabelText': {
                          fontFamily: theme.custom.fontFamily.primaryBold,
                          color: theme.palette.common.black,
                          fontSize: pxToRem(12),
                          fontWeight: '400',
                          lineHeight: pxToRem(16),
                          letterSpacing: pxToRem(0.5),
                          textTransform: 'uppercase',
                        },
                      },

                      '& .favouriteWrapper': {
                        ...theme.mixins.marginLeft('auto'),
                        width: pxToRem(24),
                        height: pxToRem(24),

                        '& .heartIcon': {
                          display: 'flex',
                          margin: 0,

                          '& svg': {
                            width: pxToRem(24),
                            height: pxToRem(24),
                          },
                        },
                      },
                    },

                    '& .slideInformationBottom': {
                      display: 'flex',
                      flexDirection: 'column',
                      gap: pxToRem(12),

                      '& .timeText': {
                        color: theme.mixins.White(),
                        fontSize: pxToRem(12),
                        fontWeight: '700',
                        lineHeight: pxToRem(16),
                        textTransform: 'uppercase',
                      },

                      '& .sliderTitle': {
                        fontFamily: theme.custom.fontFamily.primaryBold,
                        fontSize: pxToRem(22),
                        fontWeight: '400',
                        lineHeight: pxToRem(32),
                        [theme.breakpoints.down('md')]: {
                          fontFamily: theme.custom.fontFamily.primaryRegular,
                          fontSize: pxToRem(20),
                        },
                      },

                      '& .slideInfoBorder': {
                        borderRadius: pxToRem(10),
                      },
                    },
                  },
                },

              },
            },

            '& .is-desktop': {

              '& .slick-slider': {
                transition: 'ease 0.5s all',
                padding: pxToRem(96) + ' 0',
                paddingRight: '20rem',
                '@media (max-width: 1920px)': {
                  paddingRight: '20rem',
                },
                '@media (max-width: 1919px)': {
                  paddingRight: '0',
                },
                '[dir="rtl"] &': {
                  opacity: 0,
                  paddingRight: '0',
                  paddingLeft: '20rem',
                  '@media (max-width: 1920px)': {
                    paddingLeft: '20rem',
                  },
                  '@media (max-width: 1919px)': {
                    paddingLeft: '0',
                  },
                },
                '& .slick-arrow': {
                  ...theme.mixins.right(pxToRem(-58)),

                  '& .MuiButtonBase-root': {
                    width: 'unset',
                    height: 'unset',
                    border: 'unset',
                    padding: pxToRem(16),

                    '@media (min-width: 1150px)': {
                      '&:hover': {
                        backgroundColor: '#FF8707',
                      }
                    },

                  },

                  '&.slick-prev': {
                    top: 'unset',
                    bottom: '50%',
                    ...theme.mixins.left('unset'),
                    transform: theme.isDirectionRTL ? 'translate(-100%, calc(100% + 4px))' : 'translate(100%, calc(100% + 4px))',
                  },

                  '&.slick-next': {
                    bottom: 'unset',
                    top: '50%',
                    ...theme.mixins.left('unset'),
                    transform: theme.isDirectionRTL ? 'translate(-100%, calc(-100% - 4px))' : 'translate(100%, calc(-100% - 4px))',
                  },

                  '&.slick-disabled': {
                    pointerEvents: 'none',
                    opacity: 0,
                  },
                },

                '& .slick-dots': {
                  display: 'flex !important',
                  alignItems: 'center',
                  gap: pxToRem(8),
                  bottom: pxToRem(48),
                  ...theme.mixins.right('calc(50% + ' + pxToRem(8) + ')'),
                  transform: theme.isDirectionRTL ? 'translate(-50%, 100%)' : 'translate(50%, 100%)',
                  margin: 0,

                  '& li': {
                    width: 'fit-content',
                    height: 'fit-content',

                    '&.slick-active button': {
                      width: pxToRem(54),
                      height: pxToRem(4),
                      backgroundColor: 'rgba(255, 255, 255, 1)',
                    },

                    '& button': {
                      padding: 0,
                      width: pxToRem(6),
                      height: pxToRem(6),
                      backgroundColor: 'rgba(255, 255, 255, 0.35)',
                      borderRadius: '1rem',
                      transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

                      '&:before': {
                        display: 'none',
                      },
                    },
                  },
                },
              },

              '& .slick-slide': {
                width: pxToRem(324),
                scale: '0.841',
                transition: 'all 0.3s ease-in-out',
                '& .slide-inner-slide': {
                  marginTop: '0',
                  padding: '0',
                  transition: 'all 0.3s ease-in-out',
                  '& .slideElement picture': {
                    transition: 'all 0.3s ease-in-out',
                  },

                  '@media (min-width: 1150px)': {
                    '&:hover': {
                      '& .slideElement picture': {
                        scale: 1.05,
                      }
                    }
                  },
                },
                '&.slick-current': {
                  ...theme.mixins.paddingRight(pxToRem(24)),
                  scale: 1,
                },
              },
            },

            '& .is-mobile': {
              '& .slide-inner-slide': {
                ...theme.mixins.paddingRight(pxToRem(4)),
                width: pxToRem(320),
                maxWidth: '100%',
              }
            },
          },
        },
      },

      '& .scrollDownButton': {
        display: 'none'
      },

      '& .supergraphic-overlay-bg': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: '1',

        '& img': {
          backgroundColor: 'transparent',
          transform: theme.isDirectionRTL ? 'scaleX(-1)' : 'scaleX(1)',
        },
      },


      "@media (max-width: 767px)": {
        backgroundColor: 'transparent',

        '& .bg-wrapper-eventsHeroSlider': {
          position: 'relative',
          height: '65%',
          overflow: 'hidden',

          '& .supergraphic-overlay-bg': {
            position: 'absolute',
            width: '100%',
            height: '100%',

            '& img': {
              position: 'absolute',
              backgroundColor: 'transparent',
              scale: 2,
              transform: theme.isDirectionRTL ? 'scaleX(-1)' : 'scaleX(2)',
              top: '49%',
            },


            '@media (max-width: 767px)': {
              '& svg': {
                transform: 'rotate(6deg) translate(-58%, -9%)',
              },
            },
          },

          '& .bgSlider': {
            '& mainBgSlide': {
              height: '65%',
            },
          },

        },
      },

      '.close-btn': {
        top: "2.5rem",
      },

    },

    // ------------------------------- Events Hero Slider < -------------------------------

    '& .bgSlider': {
      '&.slick-initialized, &.slick-initialized > .slick-list > .slick-track > .slick-slide': {
        minHeight: '500px !important',
        height: 'calc(var(--vh-static, 1vh) * 100) !important',
        '& .slick-slide': {
          height: 'auto',
        },
      },
      '&.slick-initialized > .slick-list > .slick-track > .slick-slide > div': {
        height: '100%',
      },
    },
    '& .mainBgSlide': {
      height: '100%',
      position: 'relative',
      overflow: 'hidden',
      transform: 'translateZ(0)',
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        ...theme.mixins.left(0),
        width: '100%',
        height: '100%',
        display: 'block',
        zIndex: 2,
      },
      '&:before': {
        backgroundColor: theme.mixins.Black(200),
      },
      '&:after': {
        backgroundImage: `linear-gradient(359.98deg, ${theme.mixins.Black(396.935)} 23.41%, ${theme.mixins.DarkBlack(100)} 65.27%, ${theme.mixins.Dark23(402.398)} 93.44%)`,
      },
      '& img': {
        objectFit: 'cover',
        objectPosition: 'center center',
      },
    },
    '& .panelsWrap': {
      display: 'flex',
      top: 0,
      bottom: 0,
      ...theme.mixins.left(0),
      ...theme.mixins.right(0),
      position: 'absolute',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    '& .leftPanel': {
      position: 'relative',
      ...theme.mixins.left('5.5%'),
      width: '45%',
      ...theme.mixins.paddingRight('40px'),
      maxWidth: '740px',
      '@media (max-width: 1299px)': {
        ...theme.mixins.left('70px'),
        width: 'calc(46% - 70px)',
      },
      '@media (max-width: 1149px)': {
        ...theme.mixins.left('30px'),
        width: 'calc(46% - 30px)',
      },
      '@media (max-width: 919px)': {
        width: 'calc(55% - 30px)',
      },
      '@media (max-width: 1199px)': {
        ...theme.mixins.paddingRight(0),
      },
      '@media (max-width: 767px)': {
        ...theme.mixins.paddingRight(0),
        ...theme.mixins.left('0'),
        marginTop: '90px',
        alignSelf: 'flex-start',
        width: '100%',
      },
    },
    '& .mobileTab': {
      '& .MuiTab-wrapper': {
        textTransform: 'none',
      },
    },
    // '& .rightPanel, & .leftPanel': {
    //   opacity: 0,
    //   '&.ready-to-see': {
    //     opacity: 1,
    //   },
    // },
    '& .rightPanel': {
      position: 'relative',
      // top: '50%',
      ...theme.mixins.right(0),
      width: '49.5%',
      transform: 'translate(0, -50%)',
      '@media (max-width: 1299px)': {
        width: '55%',
        transform: `translate(${theme.isDirectionRTL && '-' || ''}20%, -50%)`,
      },
      '& .slick-arrow': {
        opacity: 0,
      },
      '&.show-arrows': {
        '& .slick-arrow': {
          opacity: 1,
          '&.slick-disabled': {
            opacity: 0.3,
          },
          '@media (max-width: 767px)': {
            opacity: 0,
          },
        },
      },
      '@media (max-width: 1299px)': {
        width: 'calc(54% + 70px)',
        transform: `translate(${theme.isDirectionRTL && '-' || ''}15%, -50%)`,
      },
      '@media (max-width: 1149px)': {
        width: 'calc(54% - 30px)',
        transform: 'translate(0%, -50%)',
      },
      '@media (max-width: 919px)': {
        width: 'calc(45% - 30px)',
      },
      '@media (max-width: 767px)': {
        width: '100%',
        alignSelf: 'flex-end',
        marginBottom: '60px',
        transform: 'none',
        '.max-height-636 &': {
          marginBottom: '50px',
        },
        '.max-height-557 &': {
          marginBottom: '0',
        },
      },
      '& .slick-list': {
        overflow: 'visible',
      },
      '& .slick-slider': {
        margin: '0px',
        paddingBottom: '106px',
        paddingTop: '46px',
        '@media (max-width: 1439px)': {
          paddingBottom: '90px',
          paddingTop: '40px',
        },
        '@media (max-width: 1023px)': {
          paddingBottom: '80px',
          paddingTop: '80px',
        },
        '@media (max-width: 919px)': {
          paddingBottom: '70px',
          paddingTop: '70px',
        },
        '@media (max-width: 767px)': {
          paddingBottom: '0px',
          paddingTop: '0px',
        },
      },
      '& .slick-prev, & .slick-next': {
        zIndex: '100',
        width: 'auto',
        height: 'auto',
        transform: 'none',

        '&.slick-disabled': {

          '& button': {
            cursor: 'default',
            '&:hover': {
              '@media (min-width: 1150px)': {
                borderColor: theme.mixins.White(),
                backgroundColor: 'transparent',
                '& svg': {
                  color: theme.mixins.White(),
                },
              },
            },
          },
        },

        '& button': {
          color: theme.mixins.White(),
          borderColor: theme.mixins.White(),
          '& svg': {
            color: theme.mixins.White(),
          },
          '&:hover': {
            '@media (min-width: 1150px)': {
              backgroundColor: theme.mixins.White(300),
              '& svg': {
                color: theme.mixins.White(),
              },
            },
          },
        },
      },
      '& .slick-prev > button, & .slick-next > button': {
        border: '1px solid #000',
        width: pxToRem(46),
        height: pxToRem(46),
        [theme.breakpoints.up('lg')]: {
          width: pxToRem(84),
          height: pxToRem(84),
        },
      },
      '& .slick-prev::before,& .slick-next::before': {
        content: "''",
      },
      '& .slick-next, & .slick-prev': {
        top: 'auto',
        bottom: '0px',
        '& svg': {
          '@media (max-width: 1023px)': {
            position: 'relative',
            top: pxToRem(1),
            width: '0.7em',
            height: '0.7em',
          },
        },
      },
      '& .slick-prev': {
        left: theme.isDirectionRTL ? '-40px' : 'auto',
        right: theme.isDirectionRTL ? 'auto' : '78px',
        '@media (max-width: 1439px)': {
          left: theme.isDirectionRTL ? '0' : 'auto',
          right: theme.isDirectionRTL ? 'auto' : '30px',
        },
        '@media (max-width: 1149px)': {
          left: theme.isDirectionRTL ? '-80px' : 'auto',
          right: theme.isDirectionRTL ? 'auto' : '-25px',
        },
        '@media (max-width: 1023px)': {
          left: theme.isDirectionRTL ? '0' : 'auto',
          right: theme.isDirectionRTL ? 'auto' : '-25px',
        },
        '@media (max-width: 919px)': {
          left: theme.isDirectionRTL ? '0' : 'auto',
          right: theme.isDirectionRTL ? 'auto' : '66px',
        },
      },
      '& .slick-next': {
        left: theme.isDirectionRTL ? '78px' : 'auto',
        right: theme.isDirectionRTL ? 'auto' : '-40px',
        '@media (max-width: 1439px)': {
          left: theme.isDirectionRTL ? '88px' : 'auto',
          right: theme.isDirectionRTL ? 'auto' : '-60px',
        },
        '@media (max-width: 1149px)': {
          left: theme.isDirectionRTL ? '0' : 'auto',
          right: theme.isDirectionRTL ? 'auto' : '-110px',
        },
        '@media (max-width: 1023px)': {
          left: theme.isDirectionRTL ? '55px' : 'auto',
          right: theme.isDirectionRTL ? 'auto' : '-80px',
        },
        '@media (max-width: 919px)': {
          left: theme.isDirectionRTL ? '66px' : 'auto',
          right: theme.isDirectionRTL ? 'auto' : '0',
        },
      },
      '& .show-arrow': {
        opacity: 1,
      },
      '& .slick-track': {
        // display: 'flex',
        // ...theme.mixins.left('0'),
        // ...theme.mixins.right('auto'),
      },
      '& .slick-thumb': {
        textAlign: theme.mixins.textLeft(),
        position: 'relative',
        bottom: 0,
        '& li': {
          width: pxToRem(107),
          height: pxToRem(104),
          overflow: 'hidden',
          margin: 0,
          '& > a': {
            display: 'flex',
            height: '100%',
          },

          '&.slick-active a': {
            border: theme.borders.divider,
            borderColor: theme.mixins.White(),
          },
        },
        [theme.breakpoints.up('lg')]: {
          marginTop: pxToRem(16),
        },
      },
    },
    '& .slider-inner': {
      position: 'absolute',
      top: '0',
      ...theme.mixins.left('0'),
      ...theme.mixins.right('0'),
      width: '100%',
      opacity: '0',
      transform: 'translate(0%, -50%)',
      pointerEvents: 'none',
      overflow: 'hidden',
      ...theme.mixins.paddingRight('12%'),
      ...theme.mixins.paddingLeft('23px'),

      '@media (max-width: 1299px)': {
        ...theme.mixins.paddingRight('33%'),
      },
      '@media (max-width: 1650px)': {
        ...theme.mixins.paddingLeft('19px'),
      },
      '@media (max-width: 1550px)': {
        ...theme.mixins.paddingLeft('2px'),
      },
      '@media (max-width: 1499px)': {
        ...theme.mixins.paddingRight('28%'),
      },
      '@media (max-width: 919px)': {
        ...theme.mixins.paddingRight('20%'),
      },
      '@media (max-width: 767px)': {
        bottom: 0,
        top: 'auto',
        ...theme.mixins.marginLeft(0),
        ...theme.mixins.paddingLeft(16),
        ...theme.mixins.paddingRight(0),
        margin: 0,
        display: 'flex',
        overflowX: 'auto',
        opacity: '0 !important',
        transform: 'none !important',
        '&.show-me': {
          opacity: '1 !important',
        },
      },
      '&.show-me': {
        pointerEvents: 'auto',
      },
    },
    '& .scrollDownButton': {
      position: 'absolute',
      bottom: '52px',
      ...theme.mixins.left('50%'),
      padding: '15px 12px',
      transform: `translate(${!theme.isDirectionRTL && '-' || ''}50%, 0)`,
      '&:hover, &:focus': {
        background: 'transparent',
      },
      '@media (max-width: 767px)': {
        bottom: '18px',
        '& svg': {
          maxWidth: '14px',
          maxHeight: '8px',
        },
        '.max-height-645 &': {
          paddingTop: 0,
        },
        '.max-height-600 &': {
          opacity: 1,
          height: '1px',
          padding: 0,
          bottom: '50px',
        },
        '.max-height-560 &': {
          bottom: '60px',
        },
        '.max-height-557 &': {
          display: 'none',
        },
      },
    },
    '& .tabLink': {
      '& .MuiTab-wrapper': {
        textTransform: 'none',
      },
      '& .MuiTabs-scroller': {
        display: 'flex',
        overflow: 'auto !important',
      },
      '& .MuiTabs-flexContainer': {
        ...theme.mixins.paddingLeft('24px'),
        paddingBottom: '7px',
      },
      '& button': {
        fontSize: fontSize.s14px,
        lineHeight: fontSize.s24px,
        fontFamily: fontFamily.primaryBold,
        padding: '0 0 4px 0',
        marginTop: 0,
        ...theme.mixins.marginRight('30px'),
        ...theme.mixins.marginLeft(0),
        paddingBottom: 0,
        position: 'relative',
        maxWidth: 'none',
        '& span': {
          whiteSpace: 'nowrap',
        },
      },
      '& .MuiTabs-indicator': {
        height: '4px',
        marginTop: '8px',
        width: '100%',
        backgroundColor: theme.mixins.White(),
      },
      // '& .MuiTabs-indicator': {
      //   display: 'none',
      // },
    },
    '& .fallbackColor': {
      height: '100%',
      background: theme.palette.common.DisabledGrey,
    },
    '& .heading': {
      ...theme.palette.common.ellipsis(),
    },
    '& .rtlClass': {
      direction: theme.direction,
    },
    '& .botSpace': {
      height: pxToRem(24),
      background: theme.palette.common.White,
      marginBottom: pxToRem(48),
      [theme.breakpoints.up('lg')]: {
        marginBottom: theme.spacing(8),
        height: pxToRem(55),
      },
    },
    '& .accordion': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      color: theme.palette.common.White,
      padding: '0 0 0 0px',
      overflow: 'hidden',
      paddingBottom: '24px',
      position: 'relative',
      transition: 'all 0.3s ease-in-out',
      '&:before': {
        content: '\'\'',
        display: 'block !important',
        position: 'absolute',
        bottom: '0px',
        top: 'auto',
        height: '24px',
        ...theme.mixins.left('11px'),
        width: '1px',
        backgroundImage: `linear-gradient(to bottom, ${theme.mixins.White()} 0%,${theme.mixins.White()} 50%,${theme.mixins.White(0)} 51%,${theme.mixins.White(0)} 100%)`,
        backgroundPosition: '0 top',
        backgroundRepeat: 'repeat',
        backgroundSize: '100% 4px',
      },
      '&.last-child': {
        '&.only-child': {
          '& .accordionSummary:after': {
            display: 'none',
          },
          '& .accordionSummary': {
            paddingLeft: 0,
          },
          '& .accordionDetails': {
            paddingLeft: 0,
          },
        },
        '&:before': {
          display: 'none !important',
        },
      },
      '&.MuiCollapse-container': {
        marginTop: '-42px',
      },
      '&.Mui-expanded': {
        marginTop: '6px !important',
        '&:before': {
          opacity: '1 !important',
        },
      },
    },
    '& .accordionSummary': {
      paddingTop: 0,
      ...theme.mixins.paddingRight(0),
      ...theme.mixins.paddingLeft('33px'),
      paddingBottom: 0,
      position: 'relative',
      minHeight: '24px',
      lineHeight: fontSize.s24Rem,
      display: 'inline-block',
      '& *': {
        lineHeight: 'inherit',
      },
      '& > *': {
        margin: 0,
      },
      '&:after': {
        content: '\'\'',
        position: 'absolute',
        top: '50%',
        ...theme.mixins.left('8px'),
        transform: 'translateY(-50%)',
        display: 'block',
        borderRadius: borderRadius.b999px,
        width: '8px',
        height: '8px',
        backgroundColor: theme.mixins.White(400),
        boxShadow: `0px 0px 0 0px ${theme.mixins.White(400)}`,
        transition: 'all 0.3s ease-in-out',
      },
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
      '&.Mui-expanded': {
        minHeight: '24px',
        '&:after': {
          backgroundColor: theme.mixins.White(),
          boxShadow: `0px 0px 0 8px ${theme.mixins.White(400)}`,
          '@media (max-width: 1439px)': {
            boxShadow: `0px 0px 0 7px ${theme.mixins.White(400)}`,
          },
        },
        '&:before': {
          top: 'calc(50% + 20px)',
        },
      },
    },
    '& .accordionDetails': {
      paddingTop: '8px',
      ...theme.mixins.paddingRight(0),
      ...theme.mixins.paddingLeft('38px'),
      paddingBottom: '0',
      position: 'relative',
      display: 'block',
      '&:before': {
        content: '\'\'',
        position: 'absolute',
        top: '7px',
        ...theme.mixins.left('11px'),
        display: 'block',
        width: '1px',
        height: 'calc(100% - 7px)',
        backgroundImage: `linear-gradient(to bottom, ${theme.mixins.White()} 0%, ${theme.mixins.White()} 50%, ${theme.mixins.White(0)} 51%, ${theme.mixins.White()} 100%)`,
        backgroundPosition: '0 bottom',
        backgroundRepeat: 'repeat',
        backgroundSize: '100% 4px',
      },
      '&.last-child': {
        '&:before': {
          display: 'none',
        },
      },
    },
    '& .detailsCTA': {
      marginTop: pxToRem(24),
      color: theme.palette.common.White,
      '& .MuiButton-endIcon': {
        backgroundColor: theme.palette.common.White,
        '& svg': {
          color: theme.palette.common.black,
        },
      },
      '&:hover, &:focus': {
        '& .MuiButton-endIcon': {
          backgroundColor: theme.palette.common.black,
          '& svg': {
            color: theme.palette.common.White,
          },
        },
      },
      '@media (max-width: 767px)': {
        marginTop: pxToRem(16),
      },
    },
    '& .detailsTitle': {
      margin: 0,
      display: 'block',
      ...theme.palette.common.ellipsis(2),
      textOverflow: 'ellipsis',
      fontSize: '4.5rem',
      lineHeight: '1.2em',
      '@media (max-width: 1600px) and (min-width: 1439px)': {
        fontSize: '3.5rem',
      },
      '@media (max-width: 1199px)': {
        fontSize: '3rem',
      },
      '@media (max-width: 767px)': {
        fontSize: '2rem',
      },
    },
    '& .weather-widget': {
      zIndex: 5,
      position: 'absolute',
      transition: 'all 750ms cubic-bezier(0.94, 0.02, 0.59, 0.97)',
      ...theme.mixins.left('1.5rem'),
      bottom: pxToRem(120),
      direction: 'ltr',
      '&.hidden': {
        transform: `translateX(${!theme.isDirectionRTL && '-' || ''}100px)`,
        opacity: '0',
      },
      [theme.breakpoints.up('lg')]: {
        ...theme.mixins.right('5.5%'),
        ...theme.mixins.left('auto'),
        bottom: '5rem',
      },
      '@media (max-width: 850px) and (orientation: landscape)': {
        ...theme.mixins.right('5.5%'),
        ...theme.mixins.left('auto'),
        bottom: theme.spacing(10.4),
      },
    },
    '& .close-btn': {
      color: theme.mixins.Black(),
      position: 'absolute',
      top: theme.spacing(3),
      ...theme.mixins.right(theme.spacing(3)),
      zIndex: '10',
      width: pxToRem(40),
      height: pxToRem(40),
      background: theme.mixins.White(900),
      borderRadius: borderRadius.circle,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      [theme.breakpoints.up('lg')]: {
        top: theme.spacing(4),
        ...theme.mixins.right(theme.spacing(4)),
        width: pxToRem(56),
        height: pxToRem(56),
      },

      '& svg': {
        height: theme.spacing(2),
        width: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
          height: theme.spacing(4),
          width: theme.spacing(4),
        },
      },

      '&:hover': {
        '@media (min-width: 1150px)': {
          borderColor: 'transparent',
          background: theme.mixins.White(),
        },
      },

      '&.plyr__element--hide': {
        display: 'none',
      },
    },
    '& .slick-initialized': {
      '&.fullscreen': {
        height: 'calc((var(--vh-static, 1vh) * 100))',
        '& .slick-track': {
          height: 'calc((var(--vh-static, 1vh) * 100))',
        },
        '& .slide-dots': {
          bottom: '-14rem',
        },
      },
    },
    '&  [dir="ltr"] .slick-slide': {
      float: 'left',
    },
    '& div:not(.slider-inner) .slick-initialized .slick-slide': {
      // overflow: 'hidden',
      // transition: 'height 750s cubic-bezier(0.94, 0.02, 0.59, 0.97)',
      // position: 'relative',

      '& .img-to-animate-wrapper': {
        height: 'calc((var(--vh-static, 1vh) * 100))',
        '& picture': {
          height: '100%',
          width: '100%',
        },
      },

      '& .slide, & .plyr__control--overlaid': {
        opacity: 0,
      },
      '&.fullscreen': {
        height: 'calc((var(--vh-static, 1vh) * 100))',
      },
      '& img': {
        width: '100%',
        height: '100%',
      },
    },
    '& .slick-thumb li': {
      borderTop: `5px solid ${theme.mixins.Black(400)}`,
      animation: '500ms ease-out slideInFromBottom',
      width: 'auto',
      height: 'auto',
      flex: '1',
      overflow: 'hidden',
      maxHeight: pxToRem(100),
      [theme.breakpoints.up('md')]: {
        width: pxToRem(150),
      },
      [theme.breakpoints.up('lg')]: {
        width: pxToRem(192),
        height: pxToRem(113),
        flex: '0 0 auto',
        maxHeight: 'initial',
      },
    },
    '& .slick-thumb': {
      display: 'flex !important',
      transition: 'all 750ms cubic-bezier(0.94, 0.02, 0.59, 0.97)',
      '&.hidden': {
        transform: 'translateY(65px)',
      },
      [theme.breakpoints.up('lg')]: {
        justifyContent: 'flex-end',
      },
      '& li': {
        transition: 'filter 1s ease',
        filter: 'grayscale(100%)',
      },
      '& img': {
        width: '100%',
        height: '100%',
        display: 'inherit',
      },
      '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
        transition: 'all 1s ease',
        [theme.breakpoints.up('lg')]: {
          '&:hover': {
            '@media (min-width: 1150px)': {
              transform: 'scale(1.1, 1.1)',
            },
          },
        },
      },
    },
    '& .slick-thumb .slick-active': {
      filter: 'grayscale(0%)',
    },
    '& .slick-dots .slick-active': {
      borderTopColor: theme.palette.common.White,
    },
    '& .slick-dots': {
      width: 'auto',
      height: 'auto',
      bottom: pxToRem(-24),
      ...theme.mixins.right(0),
      [theme.breakpoints.down('sm')]: {
        ...theme.mixins.right(0),
        transform: 'none',
        width: '100%',
      },
      [theme.breakpoints.up('lg')]: {
        ...theme.mixins.marginRight('5.5%'),
        bottom: theme.spacing(-7),
      },
      '& li': {
        margin: '0',
      },
      '&.hidden': {
        bottom: '-7.5rem',
      },
    },
    '& .plyr': {
      height: 'calc((var(--vh-static, 1vh) * 100) - var(--notificationBar))',
      [theme.breakpoints.up('lg')]: {
        '&.plyr--playing, &.plyr--paused': {
          height: 'calc((var(--vh-static, 1vh) * 100) - var(--notificationBar))',
        },
        '&.plyr--stopped': {
          height: 'calc((var(--vh-static, 1vh) * 100) - var(--notificationBar) - 100px))',
        },
        '&.video-stopped, &.plyr--paused.video-stopped': {
          height: 'calc((var(--vh-static, 1vh) * 100) - var(--notificationBar) - 100px))',
        },
      },
      '& iframe, .plyr__controls': {
        opacity: 0,
      },
      '& iframe': {
        height: 'calc((var(--vh-static, 1vh) * 100) - var(--notificationBar))',
      },
      '& .plyr__video-wrapper': {
        height: '100%',
      },
      '& .plyr__poster': {
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      },
      '& .plyr__control': {
        display: 'none !important',
      },
    },
    '& .slide .plyr__control--overlaid': {
      width: theme.spacing(6),
      height: theme.spacing(6),
      ...theme.mixins.left('calc(50% - 10px)'),
      bottom: pxToRem(70),
      transform: `translate(calc(${theme.isDirectionRTL && '-' || ''}50% - 48px), calc(-50% - ${pxToRem(1)}))`,
      display: 'flex',
      background: 'transparent',
      border: `${pxToRem(2)} solid ${theme.palette.common.White}`,
      zIndex: '10',
      transition: 'all 750ms cubic-bezier(0.94, 0.02, 0.59, 0.97)',
      padding: '0',
      top: theme.spacing(-4),

      '@media (max-width: 850px) and (orientation: landscape)': {
        top: theme.spacing(8),
        ...theme.mixins.left('auto'),
        ...theme.mixins.right(theme.spacing(-11)),
      },

      [theme.breakpoints.up('lg')]: {
        padding: pxToRem(34),
        paddingBottom: '0',
        bottom: theme.spacing(36),
        ...theme.mixins.left('auto'),
        width: theme.spacing(13.5),
        height: theme.spacing(13.5),
        top: theme.spacing(8),
        // important is requirement to override its native css
        ...theme.mixins.right(`${theme.spacing(-30.5)} !important`),
        transform: 'none',
      },

      '& svg': {
        margin: 'auto',
        ...theme.mixins.positionLR(pxToRem(1), 'auto'),
        height: pxToRem(18),
        width: pxToRem(18),
        // we need these important propery to
        // reset its position
        ...theme.mixins.right('0 !important'),
        [theme.breakpoints.up('lg')]: {
          height: theme.spacing(5),
          width: theme.spacing(5),
        },
      },
      '&:hover': {
        '@media (min-width: 1150px)': {
          backgroundColor: 'transparent',
        },
      },
    },
    '& .ieSpecific': {
      '& .weather-widget': {
        [theme.breakpoints.up('lg')]: {
          bottom: '12.5rem',
        },
      },

      '& .slick-dots': {
        justifyContent: 'flex-end',
        position: 'relative',
        marginBottom: theme.spacing(-14),
        top: theme.spacing(-7),

        '& li': {
          flex: 'auto 0 0',

          '& img': {
            ...theme.mixins.left('0'),
          },
        },
      },
    },
    '& .slide': {
      position: 'absolute',
      width: '80%',
      // adding 36 to compensate thumbnail
      // negetive position
      bottom: pxToRem(192),
      ...theme.mixins.left('2rem'),
      zIndex: '10',
      color: theme.mixins.White(),
      transform: 'translateX(0)',
      transition: 'all 750ms cubic-bezier(0.94, 0.02, 0.59, 0.97)',
      '&.hidden': {
        transform: `translateX(calc(${!theme.isDirectionRTL && '-' || ''}100px))`,
        opacity: '0',
      },
      [theme.breakpoints.up('lg')]: {
        ...theme.mixins.paddingRight(theme.spacing(9)),
        width: '60%',
        bottom: pxToRem(71),
        ...theme.mixins.left('5.5%'),
      },
      '@media (max-width: 850px) and (orientation: landscape)': {
        ...theme.mixins.left('5.5%'),
        ...theme.mixins.paddingRight(theme.spacing(6)),
        bottom: theme.spacing(10.4),
        width: '65%',
      },
      '& .heading': {
        marginBottom: pxToRem(17),
        minHeight: pxToRem(48),
        [theme.breakpoints.up('lg')]: {
          minHeight: pxToRem(145),
        },
      },
      '& .MuiButton-root': {
        borderTopColor: theme.mixins.White(),
        borderTop: theme.borders.divider,
        color: theme.mixins.White(),

        '&:hover': {
          '@media (min-width: 1150px)': {
            '& .MuiButton-endIcon': {
              color: theme.mixins.White(),
              backgroundColor: theme.mixins.Black(),
            },
          },
        },
        '& .MuiButton-endIcon': {
          color: theme.mixins.Black(),
          backgroundColor: theme.mixins.White(),
        },
      },
    },
    '& .white': {
      color: theme.palette.common.White,
      '&:before': {
        backgroundColor: theme.palette.common.White,
      },
      [theme.breakpoints.up('lg')]: {
        letterSpacing: pxToRem(0.83),
      },
    },
    '& .weatherWidget': {
      position: 'absolute',
      ...theme.mixins.left('1.5rem'),
      direction: 'ltr',
      bottom: pxToRem(64),
      [theme.breakpoints.up('lg')]: {
        ...theme.mixins.right('5.5%'),
        ...theme.mixins.left('auto'),
        bottom: '5rem',
      },
    },
    '& .playIcon': {
      position: 'absolute',
      width: pxToRem(48),
      height: pxToRem(48),
      top: 'auto',
      bottom: theme.spacing(36),
      ...theme.mixins.positionLR('auto', '37%'),
      marginTop: pxToRem(-109 / 2),
      ...theme.mixins.marginLeft(pxToRem(-109 / 2)),
      color: theme.mixins.White(),
      borderWidth: pxToRem(2),
      borderColor: theme.mixins.White(),
      borderStyle: 'solid',
      zIndex: 8,
      transition: 'all 750ms cubic-bezier(0.94, 0.02, 0.59, 0.97)',
      transform: `translate(calc(${theme.isDirectionRTL && '-' || ''}50% - ${pxToRem(
        48
      )}), calc(-50%  - ${theme.spacing(3.125)}))`,
      '& svg': {
        width: pxToRem(18),
        height: pxToRem(18),
        position: 'relative',
        ...theme.mixins.left(pxToRem(2)),
      },
      [theme.breakpoints.up('lg')]: {
        width: pxToRem(109),
        height: pxToRem(109),
        bottom: theme.spacing(36),
        ...theme.mixins.positionLR('auto', theme.spacing(44)),
        transform: 0,
        '& svg': {
          width: theme.spacing(5),
          height: theme.spacing(5),
        },
        '&:hover': {
          '@media (min-width: 1150px)': {
            background: theme.mixins.Black(500),
            transition: 'background 0.3s ease-in-out',
          },
        },
      },
      '&.hidden': {
        transform: `translateX(calc(${!theme.isDirectionRTL && '-' || ''}100px))`,
        opacity: '0',
      },
    },
    '& .slide-inner-slide': {
      width: '100%',
      ...theme.mixins.paddingRight(24),
      marginTop: '28px',
      '@media (max-width: 1650px)': {
        paddingRight: '20px',
      },
      '@media (max-width: 1550px)': {
        paddingLeft: '10px',
        paddingRight: '10px',
      },
      '@media (max-width: 1299px)': {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
      '& .slidePictureWrapper': {
        overflow: 'hidden',
        height: 'calc((var(--vh-static, 1vh) * 100) - 395px)',
        '@media (max-width: 1439px)': {
          height: 'calc((var(--vh-static, 1vh) * 100) - 335px)',
          minHeight: '200px',
        },
        '@media (max-width: 1024px)': {
          '& picture': {
            paddingTop: 'initial',
          },
        },
        '@media (max-width: 767px)': {
          minHeight: '200px',
          height: 'calc((var(--vh-static, 1vh) * 100) - 555px)',
          maxHeight: '281px',
          '.max-height-670 &': {
            minHeight: '180px',
            height: 'calc((var(--vh-static, 1vh) * 100) - 500px)',
          },
          '.max-height-636 &': {
            height: 'calc((var(--vh-static, 1vh) * 100) - 472px)',
          },
          '.max-height-557 &': {
            minHeight: '85px',
            height: 'calc((var(--vh-static, 1vh) * 100) - 412px)',
          },
          // max-height-636
          '.max-height-636 &': {
            minHeight: '140px',
            height: 'calc((var(--vh-static, 1vh) * 100) - 472px)',
          },
          // 600px
          '.max-height-600 &': {
            minHeight: '110px',
          },
          '& picture': {
            paddingTop: 'initial',
          },
        },
        maxHeight: '445px',
        '& .slideElement': {
          transformOrigin: 'bottom',
          transition: 'transform 350ms ease-out',
          height: '100%',
          backgroundColor: theme.palette.common.defaultBgGrey,

          '@media (max-width: 1149px)': {
            transform: 'translateY(0px)',
          },

          '& img': {
            objectFit: 'cover',
            objectPosition: 'center center',
            display: 'block',
            transition: 'all 350ms ease-out',
          },
        },
      },
      '& .slideInformation': {
        textAlign: theme.mixins.textLeft(),
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 16px 25px 16px',
        position: 'relative',

        '@media (max-width: 1650px)': {
          paddingRight: '8px',
          paddingLeft: '8px',
        },

        '@media (max-width: 11490px)': {
          paddingTop: '10px',
          paddingBottom: '15px',
        },

        '& .slideInfoBorder': {
          height: '100%',
          position: 'absolute',
          top: '0',
          ...theme.mixins.left(0),
          width: '100%',
          transition: 'all 350ms ease-out',
          borderBottom: `1px solid ${theme.palette.common.MediumGray2(500)}`,
          zIndex: -1,
          '&.no-sub-title.no-title': {
            display: 'none',
          },
        },

        '& .sliderTitle': {
          color: theme.mixins.White(),
          ...theme.palette.common.ellipsis(1),
          fontSize: '1.4rem',
          lineHeight: '1.4em',
          '@media (max-width: 1899px)': {
            fontSize: '1.3rem',
          },
          '@media (max-width: 1799px)': {
            fontSize: '1.2rem',
          },
          '@media (max-width: 1439px)': {
            fontSize: '1.4rem',
          },
          '@media (max-width: 1023px)': {
            fontSize: '1.2rem',
          },
          '@media (max-width: 767px)': {
            fontSize: '1.2rem',
          },
        },
        '& .sliderSubTitle': {
          color: theme.mixins.White(),
          transition: 'opacity 350ms',
          ...theme.palette.common.ellipsis(1),
        },
      },
      '@media (min-width: 1150px)': {
        '& .slidePictureWrapper': {
          '& .slideElement': {
            transform: 'translateY(20px)',
          },
        },
        '& .slideInformation': {
          '& .slideInfoBorder': {
            transform: 'translateY(-24px)',
            '&.no-sub-title': {
              transform: 'translateY(-3px)',
            },
            '&.no-title': {
              transform: 'translateY(-48px)',
            },
          },
          '& .sliderSubTitle': {
            opacity: 0,
          },
        },
        '&:hover': {
          '& .slidePictureWrapper': {
            '& .slideElement': {
              transform: 'translateY(0px)',
            },
          },
          '& .slideInformation': {
            '& .slideInfoBorder': {
              backgroundColor: theme.mixins.White(200),
              transform: 'translateY(0px)',
              '&.no-sub-title': {
                transform: 'translateY(-3px)',
              },
            },
            '& .sliderSubTitle': {
              opacity: 1,
            },
          },
        },
      },
      '@media (max-width: 1149px)': {
        '& .slidePictureWrapper': {
          '& .slideElement': {
            transform: 'translateY(0px)',
          },
        },
        '& .slideInformation': {
          '& .slideInfoBorder': {
            backgroundColor: theme.mixins.White(200),
            transform: 'translateY(0px)',
          },
          '& .sliderSubTitle': {
            opacity: 1,
          },
        },
      },
      '@media (max-width: 767px)': {
        ...theme.mixins.paddingRight(16),
        width: 'calc(100vw - 139px)',
        maxWidth: '240px',
      },
      '& .favorite': {
        '& .heartIcon': {
          marginTop: '19px',
          ...theme.mixins.marginRight('18px'),
        },
      },
    },
    '& .is-mobile': {
      display: 'inherit',
      '@media (min-width: 768px)': {
        display: 'none !important',
      },
    },
    '& .is-desktop': {
      display: 'inherit',
      '@media (max-width: 767px)': {
        display: 'none !important',
      },
    },
  },


  overlayWrapper: {
    '&.eventsHeroSliderVideo': {
      zIndex: '1320 !important',

      '& .MuiBackdrop-root': {
        right: 0,
      },

      '& .MuiDialogTitle-root, & .overlayCloseBtn': {
        display: 'none',
      },

      '& .MuiDialogContent-root': {
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        padding: '0',

        '& .slide-content-wrapper': {
          width: '100%',
        },
      },

      '& .pictureWithArticle': {
        padding: '51vh',
      },

      '& .plyr.plyr--video': {
        maxHeight: '100vh',
      },

      '& .plyr iframe': {
        height: '100%',
        maxHeight: '100vh',
      },

      '& .close-btn': {
        top: '50%',
        transform: 'translateY(calc(2.25rem - 50vh))',
        '@media (min-width: 1024px)': {
          top: '5rem',
          transform: 'translateY(0)',
        },

      },
    },
  },
}));

export default useStyles;
